import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale, faFemale } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function CourtsList() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [courts, setCourts] = useState([]);
  const currentUser = useSelector((state) => state.auth?.user);
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${apiBaseURL}/courts`)
        .then((response) => {
          setCourts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching courts:", error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => clearInterval(intervalId);
  }, [apiBaseURL]);

  const openCourts = courts.filter((court) => court.status === "open");
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const copyLinkToClipboard = (courtId) => {
    const link = `${window.location.origin}/courts/detail/${courtId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };
  const handleAvatarClick = (court) => {
    setSelectedCourt(court);
    setAvatarModalIsOpen(true);
  };
  const handleShare = (courtId) => {
    const link = `${window.location.origin}/courts/detail/${courtId}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Check out this court!",
          text: "Here is a link to a court you might be interested in:",
          url: link,
        })
        .catch((error) => console.log("Error sharing", error));
    } else {
      copyLinkToClipboard(courtId);
    }
  };
  const handleVerify = (courtId) => {
    axios
      .post(
        `${apiBaseURL}/courts/verify/${courtId}`,
        {
          roomPassword: enteredPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
          },
        }
      )
      .then((response) => {
        alert("Successfully verified!");
        setShowPasswordModal(false);

        setTimeout(() => {
          navigate(`/courts/detail/${selectedCourt._id}`); // Redirects to a court detail page, adjust route as necessary
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alert("Incorrect password. Please try again.");
        } else {
          console.log(error.message);
        }
      });
  };
  const verifyPassword = () => {
    // In reality, you'd want to verify the password with your backend API
    handleVerify(selectedCourt._id);
  };
  const handleModalClose = () => {
    setShowPasswordModal(false);
    setEnteredPassword("");
  };
  function formatDateTime(dateTimeString) {
    if (dateTimeString) {
      // Split date and time
      const [datePart, timePart] = dateTimeString.split("T");
      const [year, month, day] = datePart.split("-");
      let [hours, minutes] = timePart.split(":");

      // Convert to 12-hour format with AM/PM
      const amPm = parseInt(hours, 10) >= 12 ? "PM" : "AM";
      hours = (((parseInt(hours, 10) - 1) % 12) + 1).toString(); // Convert hour '0' to '12' and 24-hour to 12-hour format

      // Days of the week
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayOfWeek = days[new Date(datePart).getDay()];

      return `${year}-${month}-${day} ${hours}:${minutes} ${amPm} - ${dayOfWeek}`;
    }
  }
  const handleViewDetail = (court) => {
    if (!currentUser) {
      alert("You must be logged in to view this court.");
      navigate("/login");

      return;
    }
    const userHasSignedUp = court.list_of_players.some(
      (player) =>
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("confirmed")) ||
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("verified")) ||
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("feiji")) ||
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("removed")) ||
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("completed")) ||
        (String(player.user._id) === String(currentUser.userId) &&
          String(player.booking_status) === String("cancelled"))
    );

    if (court.requirement === "private" && !userHasSignedUp) {
      setSelectedCourt(court);
      setShowPasswordModal(true);
      return;
    }
    navigate(`/courts/detail/${court._id}`); // Redirects to a court detail page, adjust route as necessary
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Available Courts</h2>
      <input
        type="text"
        className="form-control mb-4"
        placeholder="Search for a court..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="row">
        {openCourts
          .filter(
            (court) =>
              court.requirement
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              formatDateTime(court.start_time).includes(
                searchTerm.toLowerCase()
              ) ||
              formatDateTime(court.end_time).includes(
                searchTerm.toLowerCase()
              ) ||
              court._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
              court.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              court.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (court.created_by &&
                court.created_by.username
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()))
          )
          .map((court) => (
            <div key={court._id} className="col-md-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title mb-0">Court: {court.name}</h3>
                  <small className="text-muted">ID: {court._id}</small>
                </div>

                <div className="card-body">
                  <p className="mb-2">
                    <strong>Created By:</strong>
                    {/* <img
                      style={{
                        width: "80px", // Changed
                        height: "80px", // Changed
                        borderRadius: "50%",
                      }}
                      src={`data:image/jpeg;base64,${court.created_by?.avatar}`}
                      alt={`${court.created_by?.username}'s avatar`}
                      onClick={() => handleAvatarClick(court)}
                    />
                    {"  "} */}
                    {"  "}
                    <strong>{court.created_by?.username}</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Location:</strong> {court.location}
                  </p>
                  <p className="mb-2">
                    <strong>Start Time:</strong>{" "}
                    {formatDateTime(court.start_time)}
                  </p>
                  <p className="mb-2">
                    <strong>End Time:</strong> {formatDateTime(court.end_time)}
                  </p>

                  <div
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <strong style={{ marginRight: "10px" }}>Players:</strong>
                    <span style={{ marginRight: "20px" }}>
                      {
                        court.list_of_players.filter(
                          (player) =>
                            player.booking_status === "confirmed" ||
                            player.booking_status === "completed"
                        ).length
                      }
                      /{court.maximum_players}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        padding: "4px 12px",
                        margin: "0 5px",
                        backgroundColor: "#e6f7ff",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMale}
                        style={{ color: "#3498db", marginRight: "8px" }}
                      />
                      {
                        court.list_of_players.filter(
                          (player) =>
                            (player.booking_status === "confirmed" ||
                              player.booking_status === "completed") &&
                            player.user.gender === "male"
                        ).length
                      }
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        padding: "4px 12px",
                        margin: "0 5px",
                        backgroundColor: "#fff0f0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFemale}
                        style={{ color: "#e74c3c", marginRight: "8px" }}
                      />
                      {
                        court.list_of_players.filter(
                          (player) =>
                            (player.booking_status === "confirmed" ||
                              player.booking_status === "completed") &&
                            player.user.gender === "female"
                        ).length
                      }
                    </div>
                  </div>

                  <p className="mb-2">
                    <strong>Room Type:</strong>{" "}
                    {capitalizeFirstLetter(court.requirement)}
                  </p>
                  <button
                    onClick={() => handleViewDetail(court)}
                    className="btn btn-primary mt-2 ml-2"
                    style={{ marginRight: "20px" }}
                  >
                    View Details
                  </button>
                  <button
                    onClick={() => handleShare(court._id)}
                    className="btn btn-secondary mt-2 ml-2"
                    style={{ marginRight: "20px" }}
                  >
                    Share
                  </button>
                  <button
                    onClick={() => copyLinkToClipboard(court._id)}
                    className="btn btn-info mt-2 ml-2"
                  >
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          ))}
        {openCourts.length === 0 && (
          <div className="col-12 text-center">
            <p>No available courts at the moment.</p>
          </div>
        )}
      </div>
      <Modal
        isOpen={showPasswordModal}
        onRequestClose={handleModalClose}
        contentLabel="Password Input Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            outline: "none",
            width: "100%",
            maxWidth: "500px",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
          },
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter Room Pin</h5>
              <button
                type="button"
                className="close"
                onClick={() => setShowPasswordModal(false)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#333",
                  fontSize: "1.5rem",
                  opacity: "0.7",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                value={enteredPassword}
                onChange={(e) => {
                  if (e.target.value.match(/^\d{0,6}$/)) {
                    setEnteredPassword(e.target.value);
                  }
                }}
                placeholder="Enter 6 number Pin"
                pattern="\d{6}"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowPasswordModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={verifyPassword}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={avatarModalIsOpen}
        onRequestClose={() => setAvatarModalIsOpen(false)}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "0",
            border: "1px solid #ccc",
            width: "80%", // For mobile
            maxWidth: "500px", // For desktop
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            outline: "none",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "999",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          {/* <img
            style={{
              width: "100%",
              height: "auto",
            }}
            src={`data:image/jpeg;base64,${selectedCourt?.created_by?.avatar}`}
            alt={`${selectedCourt?.created_by?.username}'s avatar`}
          /> */}
          <button
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              background: "#ffffff",
              border: "2px solid #333",
              borderRadius: "50%",
              cursor: "pointer",
              padding: "10px",
              fontSize: "20px",
              lineHeight: "1",
              color: "#333",
              width: "50px",
              height: "50px",
              textAlign: "center",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
            }}
            onClick={() => setAvatarModalIsOpen(false)}
          >
            &times;
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CourtsList;
