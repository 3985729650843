import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authenticateUserWithOTP } from "./../../actions/authActions";
import { Card } from "react-bootstrap";

function LoginWithOTP() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);
  const [responseMessage, setResponseMessage] = useState("");
  const [countdown, setCountdown] = useState(180); // initial value of 60 seconds

  const [formData, setFormData] = useState({
    phone: "",
    countryCode: "60",
    code: "",
  });
  const [isCodeSent, setIsCodeSent] = useState(false);

  useEffect(() => {
    let timer;

    if (isCodeSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (!countdown) {
      setIsCodeSent(false);
      setCountdown(180); // reset countdown
    }

    return () => clearTimeout(timer); // Clear the timer to prevent memory leaks
  }, [isCodeSent, countdown]);
  const dispatch = useDispatch();

  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const navigateToPassLogin = async () => {
    navigate("/login");
  };
  const navigateToRegister = async () => {
    navigate("/register");
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendOtp = async (e) => {
    e.preventDefault();

    try {
      if (!formData.phone.startsWith(formData.countryCode)) {
        var fullPhoneNumber = formData.countryCode + formData.phone;
      }
      const response = await axios.post(`${apiBaseURL}/users/login/sendcode`, {
        phone: fullPhoneNumber,
      });
      setIsCodeSent(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    var originalPhoneNumber = formData.phone;

    try {
      if (!formData.code) {
        setResponseMessage("Please fill in the OTP.");
        return;
      }
      if (!formData.phone.startsWith(formData.countryCode)) {
        var fullPhoneNumber = formData.countryCode + formData.phone;

        formData.phone = fullPhoneNumber;
      }

      dispatch(authenticateUserWithOTP(formData, navigate));
    } catch (error) {
      console.error(error.message);
    } finally {
      setFormData({ phone: originalPhoneNumber });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Card>
            <Card.Header as="h3" className="text-center bg-primary text-white">
              Account Login
            </Card.Header>
            <Card.Body>
              <form className="card p-4" onSubmit={verifyOtp}>
                <div className="mb-3 row">
                  <label className="form-label col-sm-2 col-form-label">
                    Phone
                  </label>
                  <div className="col-sm-2">
                    <select
                      name="countryCode"
                      onChange={handleChange}
                      value={formData.countryCode}
                      className="form-control"
                      required
                    >
                      <option value="60">+60 (Malaysia)</option>
                      <option value="65">+65 (Singapore)</option>{" "}
                    </select>
                  </div>
                  <div className="col-sm-8">
                    <input
                      name="phone"
                      type="tel"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className={`btn ${
                    isCodeSent ? "btn-secondary" : "btn-success"
                  }`}
                  onClick={sendOtp}
                  disabled={isCodeSent}
                >
                  {isCodeSent ? `Resend in ${countdown} seconds` : "Send Code"}
                </button>

                {isCodeSent && (
                  <div
                    className="mb-3"
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <input
                      name="code"
                      type="number"
                      className="form-control"
                      value={formData.code}
                      onChange={handleChange}
                      placeholder="Enter Verification Code"
                      required
                    />
                  </div>
                )}

                {isCodeSent && (
                  <button
                    disabled={!isCodeSent}
                    type="submit"
                    className="btn btn-success w-100"
                  >
                    Submit
                  </button>
                )}
                <div className="d-flex justify-content-between">
                  <a href="#" onClick={() => navigateToRegister()}>
                    No Account Yet? Click here to Register
                  </a>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  OR
                </div>
                <button
                  className="btn btn-secondary"
                  onClick={() => navigateToPassLogin()}
                >
                  Login with Password Instead?
                </button>
              </form>
            </Card.Body>
          </Card>
          {errorMessage && (
            <div className="mt-4 alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {responseMessage.message && (
            <div
              className={`mt-4 alert alert-${
                responseMessage.type === "success" ? "success" : "danger"
              }`}
              role="alert"
            >
              {responseMessage.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginWithOTP;
